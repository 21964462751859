
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.legend_item {
  height: 50px;
  padding: 0 11px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  width: 100%;

  @include mixins.mainfont(400, 14, colors.$black, 18);
}